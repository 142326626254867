import { Injectable, Inject } from "@angular/core";
import { GlossaryService } from "./GlossaryService";
import { DOCUMENT } from "@angular/common";

@Injectable({
	providedIn: 'root'
})
export class SeoService
{
	constructor(@Inject(DOCUMENT) private doc, private glossaryService: GlossaryService) 
	{
	}

	SetCanonicalURL(path: string)
	{
		let link: HTMLLinkElement = this.doc.createElement('link');
		link.setAttribute('rel', 'canonical');
		link.setAttribute('href', `${this.glossaryService.BaseUrl}${path}`);
		this.doc.head.appendChild(link);
	}
} 