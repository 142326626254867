import { Input } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RetirementFundEvent } from 'src/DataModel/FinancialInformation';
import { LifeProjectionService } from 'src/Services/LifeProjectionService';
import { RetirementFundComponent } from './retirement.fund.component';
import { SavingsComponent } from './savings.component';


@Component({
    selector: 'retirement-fund-summary',
    templateUrl: `./retirement.fund.summary.component.html`
})
export class RetirementFundSummaryComponent
{
    @Input() retirementFundEvent: RetirementFundEvent;
    constructor(public dialog: MatDialog, private lifeProjectionService: LifeProjectionService) 
    {
    }

    public get SummaryText()
    {
        return `$${this.retirementFundEvent.CurrentFundSize ?? 0} today | $${this.retirementFundEvent.AnnualContribution ?? 0} per year`;
    }

    Edit(): void
    {
        const dialogRef = this.dialog.open(RetirementFundComponent, {
            data: this.retirementFundEvent,
        });

        dialogRef.afterClosed().subscribe(result =>
        {
            this.lifeProjectionService.OnModify();
        });
    }

    Remove()
    {
        this.lifeProjectionService.RemoveIncomeEvent(this.retirementFundEvent);
    }
}