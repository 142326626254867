import { Component } from '@angular/core';
import { GlossaryService } from '../Services/GlossaryService';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent
{
	constructor(public glossaryService: GlossaryService)
	{
	}
}
