import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler
{
	constructor() { }
	handleError(error)
	{
		if (!environment.production)
		{
			alert(error);
			// IMPORTANT: Rethrow the error otherwise it gets swallowed
			throw error;
		}
	}
}