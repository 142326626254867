import { Input } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FutureGoalEvent } from 'src/DataModel/FinancialInformation';
import { LifeProjectionService } from 'src/Services/LifeProjectionService';
import { FutureGoalComponent } from './future.goal.component';

@Component({
    selector: 'future-goal-summary',
    templateUrl: `./future.goal.summary.component.html`
})
export class FutureGoalSummaryComponent
{
    @Input() futureGoalEvent: FutureGoalEvent;
    constructor(public dialog: MatDialog, private lifeProjectionService: LifeProjectionService) 
    {
    }

    public get Goal()
    {
        return `${this.futureGoalEvent.Goal ?? 0}`;
    }

    public get SummaryText()
    {
        return `$${Math.round(this.futureGoalEvent.CurrentValue ?? 0).toLocaleString()} on year ${Math.round(this.futureGoalEvent.Year ?? 0).toLocaleString()}`;
    }

    Edit(): void
    {
        const dialogRef = this.dialog.open(FutureGoalComponent, {
            data: this.futureGoalEvent,
        });

        dialogRef.afterClosed().subscribe(result =>
        {
            this.lifeProjectionService.OnModify();
        });
    }

    Remove()
    {
        this.lifeProjectionService.RemoveFutureGoal(this.futureGoalEvent);
    }
}