import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { environment } from 'src/environments/environment';
import { GlossaryService } from '../Services/GlossaryService';


@Component({
	selector: 'calc',
	templateUrl: `./calc.component.html`,
	styles: [`
  	.material-icons.color_green { color: #00FF00; }
	.material-icons.color_red { color: #FF0000; }
	.material-icons.color_black { color: #000000; }
	.mat-column-correctAnswer {
		flex: 0 0 50%;
		width: 6rem
	}
	.mat-column-incorrectAnswer {
		flex: 0 0 50%;
		width: 5rem
	}
`]
}
)
export class CalcComponent 
{
	questions: any[];

	num1: number;
	num2: number;
	answer: string;
	correctAnswers: number;
	incorrectAnswers: number;
	isStarted: boolean;

	seconds: number;
	audioClick: any;
	audioError: any;
	audioSuccess: any;
	audioTimeup: any;
	gameConfigs: any[];

	selectedGameConfig: GameConfig;
	bestScores: any[];
	bestScoreSubscription: any;
	displayedColumns: string[] = ['correctAnswer', "incorrectAnswer"];
	audionInPlay:any;

	constructor(private firestore: AngularFirestore)
	{
		this.bestScores = [];
		this.answer = "";
		this.correctAnswers = 0;
		this.incorrectAnswers = 0;
		this.questions = [];
		this.isStarted = false;

		if (environment.production)
		{
			this.audioClick = this.LoadAudio("../../../assets/audio/click.mp3");
			this.audioError = this.LoadAudio("../../../assets/audio/error.mp3");
			this.audioSuccess = this.LoadAudio("../../../assets/audio/sucsess.mp3");
			this.audioTimeup = this.LoadAudio("../../../assets/audio/timesup.mp3");
		}
		window.onload = function () 
		{
			var scale = screen.width / 250;
			var vpEl = document.getElementById('vwPrt');
			vpEl.setAttribute('content', 'width=250, initial-scale=' + scale);
		}
		this.InitGameConfigs();
	}

	InitGameConfigs()
	{
		this.gameConfigs = [];
		var gameConfig = new GameConfig();
		gameConfig.name = "Easy Multiplication";
		gameConfig.time = 120;
		gameConfig.operator = "x";
		gameConfig.maxNumber = 12;
		this.gameConfigs.push(gameConfig);


		gameConfig = new GameConfig();
		gameConfig.name = "Easy Divide";
		gameConfig.time = 120;
		gameConfig.operator = "/";
		gameConfig.maxNumber = 12;
		this.gameConfigs.push(gameConfig);

		gameConfig = new GameConfig();
		gameConfig.name = "Hard Multiplication";
		gameConfig.time = 180;
		gameConfig.operator = "x";
		gameConfig.maxNumber = 15;
		this.gameConfigs.push(gameConfig);
	}

	SelectGameConfig(gameConfig: GameConfig)
	{
		this.selectedGameConfig = gameConfig;
		this.seconds = this.selectedGameConfig.time;
		this.LoadTopScores();
	}

	LoadAudio(path: string)
	{
		var audio = new Audio();
		audio.src = path;
		audio.load();
		return audio;
	}

	Start()
	{
		if (this.selectedGameConfig != null)
		{
			this.RenewFormula();
			this.isStarted = true;
			this.questions = [];
			this.correctAnswers = 0;
			this.incorrectAnswers = 0;
			this.PlayAudio(this.audioTimeup);

			const timer = setInterval((() =>
			{
				this.seconds--;
				if (this.seconds <= 0)
				{
					this.seconds = 0;
					this.isStarted = false;
					clearInterval(timer);
					this.PlayAudio(this.audioTimeup);
					this.SaveResults();
				}
			}).bind(this), 1000);
		}
	}

	async SaveResults()
	{
		this.firestore
			.collection("calc_results")
			.add({
				game: this.selectedGameConfig.name,
				datetime: new Date(),
				correctAnswers: this.correctAnswers,
				incorrectAnswers: this.incorrectAnswers
			});
	}

	LoadTopScores()
	{
		if (this.bestScoreSubscription != null)
		{
			this.bestScoreSubscription.unsubscribe();
		}
		var collection = this.firestore
			.collection("calc_results", ref => ref.where('game', '==', this.selectedGameConfig.name).orderBy("correctAnswers", "desc").limit(5))
			.snapshotChanges();

		this.bestScoreSubscription = collection.subscribe(data =>
		{
			this.bestScores = data.map(e => e.payload.doc.data());
		});

	}

	PlayAudio(audio)
	{
		if (environment.production)
		{
			if (this.audionInPlay != null)
			{
				this.audionInPlay.pause();
				this.audionInPlay.currentTime = 0;
			}
			this.audionInPlay = audio;
			audio.play();
		}
	}

	GetRand(min, max)
	{
		return Math.round(Math.random() * (max - min) + min);
	}

	RenewFormula()
	{
		this.answer = "";
		if (this.selectedGameConfig.operator == "x")
		{
			this.num1 = this.GetRand(2, this.selectedGameConfig.maxNumber);
			this.num2 = this.GetRand(2, this.selectedGameConfig.maxNumber);
		}
		else if (this.selectedGameConfig.operator == "/")
		{
			this.num2 = this.GetRand(2, this.selectedGameConfig.maxNumber);
			this.num1 = this.num2 * this.GetRand(2, this.selectedGameConfig.maxNumber);
		}
	}

	OnKey(key)
	{
		this.PlayAudio(this.audioClick);
		if (key == -1)
		{
			if (this.answer.length > 0)
			{
				this.answer = this.answer.slice(0, this.answer.length - 1);
			}
		}
		else if (key == -2)
		{
			if (this.answer.length > 0)
			{
				this.OnSubmit();
			}
		}
		else
		{
			this.answer = this.answer.concat(key);
		}
	}

	OnSubmit()
	{
		if (this.isStarted)
		{
			var question = new Question();
			question.num1 = this.num1;
			question.num2 = this.num2;
			question.operator = this.selectedGameConfig.operator;
			question.answer = parseInt(this.answer);

			var expectedAnswer;
			if (this.selectedGameConfig.operator == "x")
			{
				expectedAnswer = this.num1 * this.num2;
			}
			else if (this.selectedGameConfig.operator == "/")
			{
				expectedAnswer = this.num1 / this.num2;
			}

			if (question.answer == expectedAnswer)
			{
				question.state = true;
				this.correctAnswers++;
				this.PlayAudio(this.audioSuccess);
			}
			else
			{
				this.incorrectAnswers++;
				question.state = false;
				this.PlayAudio(this.audioError);
				this.seconds = Math.max(0, this.seconds - 5);
			}
			this.questions.push(question);
			this.RenewFormula();
		}
	}
}

class Question
{
	num1;
	num2;
	operator;
	answer;
	state;
}


class GameConfig
{
	name: string;
	time: number;
	operator: string;
	maxNumber: number;
}