import { Input } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RentalIncomeEvent } from 'src/DataModel/FinancialInformation';
import { LifeProjectionService } from 'src/Services/LifeProjectionService';
import { RentalIncomeComponent } from './rental.income.component';

@Component({
    selector: 'rental-income-summary',
    templateUrl: `./rental.income.summary.component.html`
})
export class RentalIncomeSummaryComponent
{
    @Input() rentalIncomeEvent: RentalIncomeEvent;
    constructor(public dialog: MatDialog, private lifeProjectionService: LifeProjectionService) 
    {
    }

    public get SummaryText()
    {
        return `$${this.rentalIncomeEvent.AnnualRent ?? 0} annually`;
    }

    Edit(): void
    {
        const dialogRef = this.dialog.open(RentalIncomeComponent,
            {
                data: this.rentalIncomeEvent,
            });

        dialogRef.afterClosed().subscribe(result =>
        {
            this.lifeProjectionService.OnModify();
        });
    }

    Remove()
    {
        this.lifeProjectionService.RemoveIncomeEvent(this.rentalIncomeEvent);
    }
}