import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RetirementFundEvent } from 'src/DataModel/FinancialInformation';
import { ValidatorUtil } from 'src/Util/ValidationUtil';
import { DialogBaseComponent, NumberField } from '../Dialog/dialog.base.component';
import { SavingsSummaryComponent } from './savings.summary.component';

@Component({
  selector: 'retirement-fund-component',
  templateUrl: 'retirement.fund.component.html',
})
export class RetirementFundComponent extends DialogBaseComponent
{
  retirementFundEvent: RetirementFundEvent;
  currentFundSizeControl: NumberField;
  annualContributionControl: NumberField;
  averageYearlyReturnControl: NumberField;
  annualFundReturnControl: NumberField;

  constructor(
    public dialogRef: MatDialogRef<SavingsSummaryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RetirementFundEvent)
  {
    super(dialogRef);

    this.retirementFundEvent = data;

    this.currentFundSizeControl = this.AddNumberField("Current Savings", this.retirementFundEvent.CurrentFundSize, [ValidatorUtil.ValidateRequired()]);
    this.annualContributionControl = this.AddNumberField("Annual Savings", this.retirementFundEvent.AnnualContribution, [ValidatorUtil.ValidateRequired()]);
    this.averageYearlyReturnControl = this.AddNumberField("Yearly Savings Increase/Decrease", this.retirementFundEvent.AverageYearlyReturn, [ValidatorUtil.ValidateRequired()]);
    this.annualFundReturnControl = this.AddSliderField("Average Yearly Return (%)", this.retirementFundEvent.AnnualFundReturn,
      0, 100, 0.5, 1, false, []);
  }

  ngOnInit() 
  {
    this.dialogRef.updateSize('650px', 'auto');
  }

  OnSaveSuccess()
  {
    this.retirementFundEvent.CurrentFundSize = this.currentFundSizeControl.Value;
    this.retirementFundEvent.AnnualContribution = this.annualContributionControl.Value;
    this.retirementFundEvent.AverageYearlyReturn = this.averageYearlyReturnControl.Value;
    this.retirementFundEvent.AnnualFundReturn = this.annualFundReturnControl.Value;
  }
}
