<navigation-bar></navigation-bar>

<div class="d-flex justify-content-center">
	<div class="container mt-3">
		<div class="row d-flex align-items-center justify-content-center mt-5">
			<div class="col-12 text-lightgrey text-center">
				<h2 class="mt-5">
					Login in to {{glossaryService.ProductName}}
				</h2>
			</div>
		</div>

		<div class="row d-flex align-items-center justify-content-center">
			<div class="col-lg-6 text-center">
				<a class="btn btn-outline-primary google-button my-4 width-300px " role="button" (click)="authService.GoogleAuth()">
					<img class="icon" src="assets/img/sign-in-with-google.svg" />
					<span class="label">Sign in with Google</span>					
				</a>
				<h4 class="my-3 mx-auto text-lightgrey">or with your Wealthly account</h4>
				<form [formGroup]="form" (ngSubmit)="onSubmit()">
					<div class="form-group">
						<input type="email" class="form-control width-300px mx-auto text-center" aria-describedby="emailHelp"
							[ngClass]="{'error': isValid('Email')}" placeholder="Email" formControlName="Email">
						<input type="password" class="form-control width-300px mx-auto my-1 text-center" [ngClass]="{'error': isValid('Password')}"
							placeholder="Password" formControlName="Password">
					</div>

					<div class="mx-auto my-2 text-center errorMessage">
						<span *ngIf="hasErrors">{{errorMessage}}</span>
					</div>
					<button class="btn btn-primary border-0 width-300px" style="cursor:pointer" type="submit">
						Login
					</button>
					<div class="mx-auto text-center">
							<h5 class="text-lightgrey mt-3"><a href="/forgot">Forgot your password?</a></h5>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>