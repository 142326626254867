import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from './auth.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { GlossaryService } from '../Services/GlossaryService';
import { SeoService } from '../Services/SeoService';
import { ValidatorUtil } from '../Util/ValidationUtil';

@Component({
	selector: 'manage',
	templateUrl: 'Manage.component.html',
})
export class ManageComponent
{
	errorMessage: string;
	hasErrors: boolean;
	form: FormGroup;

	constructor(private fb: FormBuilder, public auth: AuthService, private title: Title, public activatedRoute: ActivatedRoute,
		public glossaryService: GlossaryService, private seoService: SeoService)
	{
		this.form = fb.group({
			Email: new FormControl('', [Validators.required, ValidatorUtil.ValidateEmailControl()]),
			Password: new FormControl('', Validators.required),
		});

		title.setTitle(`Log in to ${glossaryService.ProductName}`);

		seoService.SetCanonicalURL("/login");
	}

	ngOnInit()
	{
		// this.activatedRoute.queryParams.subscribe(params =>
		// {
		// 	if (params.code != undefined)
		// 	{
		// 		this.auth.loginCallback(params, (error =>
		// 		{
		// 			this.hasErrors = true;
		// 			this.errorMessage = error.json().message;
		// 		}).bind(this));
		// 	}
		// });
			}

	showErrors()
	{
		for (let ctrlName in this.form.controls)
		{
			let ctrl: FormControl = <FormControl>this.form.controls[ctrlName];
			if (!ctrl.valid)
			{
				if (ctrl.errors != null)
				{
					if (ctrl.errors.required)
					{
						this.hasErrors = true;
						this.errorMessage = "Please fill all required fields";
						break;
					}
					else if (ctrl.errors.invalidEmail)
					{
						this.hasErrors = true;
						this.errorMessage = "Please enter a valid email address";
						break;
					}
				}
			}
		}
	}

	onSubmit() 
	{
		// if (this.form.valid)
		// {
		// 	this.auth.login(this.form.value, (error =>
		// 	{
		// 		this.hasErrors = true;
		// 		this.errorMessage = error.error.message;
		// 	}).bind(this));
		// }
		// else
		// {
		// 	this.showErrors();
		// }
	}

	isValid(control)
	{
		return this.form.controls[control].invalid && this.form.controls[control].touched
	}
}
