export class ValidatorUtil
{
	public static ValidateEmailControl()
	{
		return control =>
		{
			var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

			return regex.test(control.value) ? null : { invalidEmail: true }
		}
	}

	public static ValidateMinMax(min:number, max:number)
	{
		return control =>
		{
			return (control.value < min || control.value > max) 
				? { message: `Value should be between ${min} and ${max}` }
				: null;
		}
	}

	public static ValidateRequired()
	{
		return control =>
		{
			return (control.value == null) 
				? { message: `Required` }
				: null;
		}
	}
}