import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate
{
    constructor(private auth: AuthService, private router: Router) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> 
    {
        return new Promise((resolve, reject) =>
        {
            getAuth().onAuthStateChanged((user: User) =>
            {
                if (user)
                {
                    console.log('User is logged in');
                    resolve(true);
                }
                else
                {
                    console.log('User is not logged in');
                    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                    resolve(false);
                }
            });
        });
    }
}