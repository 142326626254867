import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { tap } from "rxjs/operators";
import { Router } from '@angular/router';
import { environment } from '../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor
{
	constructor(public auth: AuthService, private router: Router) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
	{
		let reqClone = req.clone(
			{
				// setHeaders:
				// {
				// 	Authorization: `Bearer ${this.auth.Token}`
				// }
			});
 
		return next.handle(reqClone)
			.pipe(tap( null,
				(err: any) =>
				{
					// if (err instanceof HttpErrorResponse)
					// {
					// 	if (!environment.production)
					// 	{
					// 		alert(JSON.stringify(err, null, 2))
					// 	}
					// 	console.error('Request url: ' + req.url);
					// 	console.error(JSON.stringify(err, null, 2));
					// 	if (err.status === 401)
					// 	{
					// 		this.auth.Logout();
					// 		this.router.navigateByUrl('/login');
					// 	}
					// }
				}
			));
	}
}