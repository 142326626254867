import { Input, OnInit } from '@angular/core';
import { forwardRef } from '@angular/core';
import { Component } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IFormFieldComponent, StringField } from './dialog.base.component';


@Component({
  selector: 'string-field',
  templateUrl: `./string.field.component.html`,
  providers: [
    { 
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StringFieldComponent),
      multi: true
    }
  ]
}
)
export class StringFieldComponent implements IFormFieldComponent<string>, OnInit
{
  @Input() formField: StringField;
  formControl: FormControl;
  constructor()
  {
  }

  ngOnInit()
  {
    this.formField.RegisterFormFieldComponent(this);
    this.formControl = new FormControl(this.formField.initialValue, this.formField.validators);
  }

  IsValid(): boolean
  {
    return !this.formControl.invalid;
  }

  MarkAllAsTouched() 
  {
    this.formControl.markAllAsTouched();
  }

  get Value()
  {
    return this.formControl.value;
  }
  
  public getErrorMessage()
  {
    return this.formControl?.invalid ? this.formControl.errors.message : '';
  }
}

