<h1 mat-dialog-title>Retirement Income</h1>
<div mat-dialog-content>
    <div class="row">
        <div class="col">
            <number-field class="row" [formField]='expectedAnnualIncomeControl' ></number-field>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="accent" (click)="onSaveClick()" cdkFocusInitial>Save</button>
    <button mat-raised-button (click)="onDiscardClick()">Discard</button>
</div>