import { Input, OnInit } from '@angular/core';
import { forwardRef } from '@angular/core';
import { Component } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IFormFieldComponent, SliderField } from './dialog.base.component';

@Component({
  selector: 'slider-field',
  templateUrl: `./slider.field.component.html`,
 styleUrls: [`./slider.field.component.scss`],
  providers: [
    { 
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SliderFieldComponent),
      multi: true
    }
  ]
}
)
export class SliderFieldComponent implements IFormFieldComponent<number>, OnInit
{
  @Input() formField: SliderField;
  value: number;

  constructor()
  {
  }

  ngOnInit()
  {
    this.formField.RegisterFormFieldComponent(this);
    this.value = this.formField.initialValue ?? this.formField.Min ?? 0;
  }

  IsValid(): boolean
  {
    return true;
  }

  MarkAllAsTouched() 
  {
  }

  get Value()
  {
    return this.value;
  }

  FormatLabel = (value: number) => 
  {
    if (!value)
    {
      return "0";
    }
    var decimals = this.formField.Decimals;
    return value.toLocaleString(undefined, { maximumFractionDigits: decimals, minimumFractionDigits: decimals });  
  }
}

