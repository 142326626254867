<mat-card>
    <mat-card-title>
        <span>Personal Assets</span>

        <button class="mx-2" mat-mini-fab [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>add</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="AddHouse()">
                <mat-icon>house</mat-icon>
                <span>House</span>
            </button>
            <button mat-menu-item (click)="AddApartment()">
                <mat-icon>apartment</mat-icon>
                <span>Apartment</span>
            </button>
            <button mat-menu-item>
                <mat-icon>directions_car</mat-icon>
                <span>Vehicle</span>
            </button>
        </mat-menu>
    </mat-card-title>
    <mat-card-content>
        <!-- <div *ngFor="let baseAsset of financialInformation.Assets.PersonalAssets.Items ; let i index" >            
   
            <div *ngIf="baseAsset.GetAssetType() == AssetType.House"><house-asset [house]="baseAsset"></house-asset></div>
            <div *ngIf="baseAsset.GetAssetType() == AssetType.Apartment">Apartment</div>
        </div> -->
    </mat-card-content>
</mat-card>
<!-- <mat-divider [inset]="true"></mat-divider> -->
<mat-card>
    <mat-card-title>Investment Assets</mat-card-title>
    <mat-card-content>My content</mat-card-content>
</mat-card>
