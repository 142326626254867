<navigation-bar2></navigation-bar2>
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<div *ngIf="isLoaded" class="container">
	<div class="row justify-content-center">
		<div class="col">
			<number-field [formField]='birthYearControl'></number-field>
		</div>
		<div class="col">
			<number-field [formField]='retireAgeControl'></number-field>
		</div>
	</div>
	<div class="row">
		<life-chart></life-chart>
	</div>
	<div class="row">
		<div class="col m-2">
			<div class="row">
				<mat-card class="mx-2" style="border: 0px solid #000">
					<mat-card-title>
						<span>Income Events</span>

						<button class="mx-2" mat-mini-fab [matMenuTriggerFor]="menu"
							aria-label="Example icon-button with a menu">
							<mat-icon>add</mat-icon>
						</button>
						<mat-menu #menu="matMenu">
							<button mat-menu-item (click)="AddSavingAndInvestments()">
								<mat-icon>savings</mat-icon>
								<span>Savings & Investments</span>
							</button>
							<button mat-menu-item (click)="AddRentalIncome()">
								<mat-icon>currency_exchange</mat-icon>
								<span>Rental Income</span>
							</button>
							<button mat-menu-item (click)="AddRetirementFund()">
								<mat-icon>accessible</mat-icon>
								<span>Retirement Fund</span>
							</button>
							<button mat-menu-item (click)="AddRetirementIncome()">
								<mat-icon>accessible</mat-icon>
								<span>Retirement Income</span>
							</button>
						</mat-menu>
					</mat-card-title>
					<mat-card-content>
						<div
							*ngFor="let incomeEvent of this.lifeProjectionService.financialInformation.IncomeEvents ; let i index">
							<div [ngSwitch]="incomeEvent.GetType()">
								<div *ngSwitchCase="IncomeEventType.Savings">
									<savings-summary [savingsEvent]="incomeEvent"></savings-summary>
								</div>
								<div *ngSwitchCase="IncomeEventType.RetirementFund">
									<retirement-fund-summary [retirementFundEvent]="incomeEvent">
									</retirement-fund-summary>
								</div>
								<div *ngSwitchCase="IncomeEventType.RentalIncome">
									<rental-income-summary [rentalIncomeEvent]="incomeEvent">
									</rental-income-summary>
								</div>
								<div *ngSwitchCase="IncomeEventType.RetirementIncome">
									<retirement-income-summary [retirementIncomeEvent]="incomeEvent">
									</retirement-income-summary>
								</div>
							</div>
						</div>
					</mat-card-content>
				</mat-card>
			</div>
		</div>
		<div class="col m-2">
			<div class="row">
				<mat-card class="mx-2" style="border: 0px solid #000">
					<mat-card-title>
						<span>Future Goals</span>

						<button class="mx-2" mat-mini-fab (click)="AddFutureGoal()">
							<mat-icon>add</mat-icon>
						</button>
						<!-- <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="AddFutureGoal()">
                                <mat-icon>savings</mat-icon>
                                <span>Savings & Investments</span>
                            </button>                           
                        </mat-menu> -->
					</mat-card-title>
					<mat-card-content>
						<div
							*ngFor="let incomeEvent of this.lifeProjectionService.financialInformation.FutureGoals ; let i index">
							<div [ngSwitch]="incomeEvent.GetType()">
								<div *ngSwitchCase="IncomeEventType.FutureGoal">
									<future-goal-summary [futureGoalEvent]="incomeEvent"></future-goal-summary>
								</div>
							</div>
						</div>
					</mat-card-content>
				</mat-card>
			</div>
		</div>
	</div>
</div>