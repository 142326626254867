import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RetirementIncomeEvent } from 'src/DataModel/FinancialInformation';
import { ValidatorUtil } from 'src/Util/ValidationUtil';
import { DialogBaseComponent, NumberField } from '../Dialog/dialog.base.component';
import { RetirementIncomeSummaryComponent } from './retirement.income.summary.component';

@Component({
  selector: 'retirement-income-component',
  templateUrl: 'retirement.income.component.html',
})
export class RetirementIncomeComponent extends DialogBaseComponent{
  retirementIncomeEvent: RetirementIncomeEvent;
  expectedAnnualIncomeControl: NumberField;

  constructor(
    public dialogRef: MatDialogRef<RetirementIncomeSummaryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RetirementIncomeEvent)
  {
    super(dialogRef);

    this.retirementIncomeEvent = data;

    this.expectedAnnualIncomeControl = this.AddNumberField("Expected Annual Income", this.retirementIncomeEvent.ExpectedAnnualIncome,
      [ValidatorUtil.ValidateRequired()]);
  }

  ngOnInit() 
  {
    this.dialogRef.updateSize('325px', 'auto');
  }

  OnSaveSuccess()
  {
    this.retirementIncomeEvent.ExpectedAnnualIncome = this.expectedAnnualIncomeControl.Value;
  }
}
