<navigation-bar></navigation-bar>

<div class="d-flex justify-content-center">
	<div class="container">
		<div class="row d-flex align-items-center justify-content-center mt-2">
			<div class="col-12 text-lightgrey text-center">
				<h2 class="mt-5">
					Create {{glossaryService.ProductName}} account
				</h2>
				<p class="pt-20 pb-20 text-lightgrey">
					MindPicto Mind Map Editor provides the best in class online editor to create and visualize your mind
					maps.
				</p>
			</div>
		</div>

		<div class="row d-flex align-items-center justify-content-center">
			<div class="col-lg-6 text-center">
				<a class="btn btn-outline-primary google-button my-4 width-300px " href="/api/user/logingoogle">
					<img class="icon" src="assets/img/sign-in-with-google.svg" />
					<span class="label">Sign in with Google</span>
				</a>
				<h4 class="my-3 mx-auto text-lightgrey">or create a new account</h4>
				<form [formGroup]="form" (ngSubmit)="onSubmit()">
					<div class="form-group">
						<input class="form-control width-300px mx-auto text-center text-darkgrey"
							[ngClass]="{'error': isValid('FirstName')}" placeholder="First name"
							formControlName="FirstName">
						<input class="form-control width-300px mx-auto my-1 text-center"
							[ngClass]="{'error': isValid('LastName')}" placeholder="Last name"
							formControlName="LastName">
						<input class="form-control width-300px mx-auto my-1 text-center"
							[ngClass]="{'error': isValid('Email')}" placeholder="Email" formControlName="Email">
						<input class="form-control width-300px mx-auto my-1 text-center"
							[ngClass]="{'error': isValid('Password')}" placeholder="Password" formControlName="Password"
							type='password'>
						<input class="form-control width-300px mx-auto my-1 text-center"
							[ngClass]="{'error': isValid('ConfirmPassword')}" placeholder="Confirm password"
							formControlName="ConfirmPassword" type='password'>
					</div>

					<div class="mx-auto my-2 text-center errorMessage">
						<span *ngIf="hasErrors">{{errorMessage}}</span>
					</div>
					<button class="btn btn-primary border-0 width-300px" style="cursor:pointer" type="submit">
						Sign Up
					</button>
			
					<div class="mt-2">
						<p class="text-lightgrey text-center">By creating an account, you agree to our
							<a href="/content/terms" target="_blank">Terms of Service</a>
							and <a href="/content/privacypolicy" target="_blank"> Privacy Policy.</a></p>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>