import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from './auth.service';
import { Title } from '@angular/platform-browser';
import { GlossaryService } from '../Services/GlossaryService';
import { SeoService } from '../Services/SeoService';

@Component({
	selector: 'signup',
	templateUrl: 'signup.component.html',
	styles: [`
        .error {
            background-color: #ffaeae
        }
    `]
})
export class SignupComponent
{
	errorMessage: string;
	hasErrors: boolean;
	form: FormGroup;

	constructor(private fb: FormBuilder, private auth: AuthService, private title: Title, public glossaryService: GlossaryService, private seoService: SeoService)
	{
		this.form = fb.group(
			{
				FirstName: new FormControl('', Validators.required),
				LastName: new FormControl('', Validators.required),
				Email: new FormControl('', [Validators.required, Validators.email]),
				Password: new FormControl('', Validators.required),
				ConfirmPassword: new FormControl('', [Validators.required, this.MatchWithControl().bind(this)])
			});

		this.form.controls["Password"].valueChanges.subscribe(this.OnPasswordChanged.bind(this))
		title.setTitle(`Create a ${this.glossaryService.ProductName} Account`);
		seoService.SetCanonicalURL("/signup");
	}

	showErrors()
	{
		for (let ctrlName in this.form.controls)
		{
			let ctrl: FormControl = <FormControl>this.form.controls[ctrlName];
			if (!ctrl.valid)
			{
				if (ctrl.errors != null)
				{
					if (ctrl.errors.required)
					{
						this.hasErrors = true;
						this.errorMessage = "Please fill all required fields";
						break;
					}
					else if (ctrl.errors.email)
					{
						this.hasErrors = true;
						this.errorMessage = "Please enter a valid email address";
						break;
					}
					else if (ctrl.errors.mismatchedFields)
					{
						this.hasErrors = true;
						this.errorMessage = "Passwords do not match";
						break;
					}
				}
			}
		}
	}

	onSubmit() 
	{
		if (this.form.valid)
		{
			// this.auth.register(this.form.value, (error =>
			// {
			// 	this.hasErrors = true;
			// 	this.errorMessage = error.json().message;
			// }).bind(this));
		}
		else
		{
			this.showErrors();
		}
	}

	isValid(control)
	{
		return this.form.controls[control].invalid && this.form.controls[control].touched
	}

	OnPasswordChanged()
	{
		this.form.controls["ConfirmPassword"].updateValueAndValidity();
	}

	MatchWithControl()
	{
		return control =>
		{
			if (this.form != null)
			{
				if (control.value !== this.form.controls["Password"].value)
				{
					return { mismatchedFields: true }
				}
			}
		}
	}
}
