import { Input } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RetirementIncomeEvent } from 'src/DataModel/FinancialInformation';
import { LifeProjectionService } from 'src/Services/LifeProjectionService';
import { RetirementIncomeComponent } from './retirement.income.component';


@Component({
    selector: 'retirement-income-summary',
    templateUrl: `./retirement.income.summary.component.html`
})
export class RetirementIncomeSummaryComponent
{
    @Input() retirementIncomeEvent: RetirementIncomeEvent;
    constructor(public dialog: MatDialog, private lifeProjectionService: LifeProjectionService) 
    {
    }

    public get SummaryText()
    {
        return `$${this.retirementIncomeEvent.ExpectedAnnualIncome ?? 0} per year`;
    }

    Edit(): void
    {
        const dialogRef = this.dialog.open(RetirementIncomeComponent, {
            data: this.retirementIncomeEvent,
        });

        dialogRef.afterClosed().subscribe(result =>
        {
            this.lifeProjectionService.OnModify();
        });
    }

    Remove()
    {
        this.lifeProjectionService.RemoveIncomeEvent(this.retirementIncomeEvent);
    }
}