import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FinancialInformation, FutureGoalEvent, IncomeEventType, RentalIncomeEvent, RetirementFundEvent, RetirementIncomeEvent, SavingsEvent } from 'src/DataModel/FinancialInformation';
import { NumberField } from 'src/Dialog/dialog.base.component';
import { LifeProjectionService } from 'src/Services/LifeProjectionService';
import { ValidatorUtil } from 'src/Util/ValidationUtil';
import { DocumentService } from '../Services/DocumentService';
import { FutureGoalComponent } from './future.goal.component';
import { RentalIncomeComponent } from './rental.income.component';
import { RetirementFundComponent } from './retirement.fund.component';
import { RetirementIncomeComponent } from './retirement.income.component';
import { SavingsComponent } from './savings.component';
import { NgxSpinnerService } from "ngx-spinner";
import { Title } from '@angular/platform-browser';


@Component({
    selector: 'life-projection',
    templateUrl: `./life.projection.component.html`
})
export class LifeProjectionComponent implements OnInit
{
    public IncomeEventType = IncomeEventType;
    public isLoaded: boolean = false;

    birthYearControl: NumberField;
    retireAgeControl: NumberField;

    constructor(private documentService: DocumentService, public lifeProjectionService: LifeProjectionService,
        public dialog: MatDialog, private spinner: NgxSpinnerService, private title: Title)
    {

    }

    async ngOnInit()
    {
        this.title.setTitle("Life Projection");
        this.spinner.show();
        await this.lifeProjectionService.Initialize();
        this.InitializeControls();
        this.spinner.hide();
        this.isLoaded = true;
    }

    async InitializeControls()
    {
        this.lifeProjectionService.OnModify();

        this.birthYearControl = new NumberField();
        this.birthYearControl.label = "I was born on";
        this.birthYearControl.initialValue = this.lifeProjectionService.financialInformation.BirthYear;
        this.birthYearControl.validators = [ValidatorUtil.ValidateRequired()];
        this.birthYearControl.valueChanged.subscribe((value) => 
        {
            this.lifeProjectionService.financialInformation.BirthYear = value;
            this.lifeProjectionService.OnModify();
        });

        this.retireAgeControl = new NumberField();
        this.retireAgeControl.label = "I will retire at";
        this.retireAgeControl.initialValue = this.lifeProjectionService.financialInformation.ExpectedRetireAge;
        this.retireAgeControl.validators = [ValidatorUtil.ValidateRequired()];
        this.retireAgeControl.valueChanged.subscribe((value) => 
        {
            this.lifeProjectionService.financialInformation.ExpectedRetireAge = value;
            this.lifeProjectionService.OnModify();
        });
    }

    AddSavingAndInvestments()
    {        
        var savingsEvent = new SavingsEvent();
        const dialogRef = this.dialog.open(SavingsComponent,
            {
                data: savingsEvent
            });

        dialogRef.afterClosed().subscribe(result =>
        {
            if (result)
            {
                this.lifeProjectionService.AddIncomeEvent(savingsEvent);
            }
        });
    }

    AddRetirementFund()
    {
        var retirementFundEvent = new RetirementFundEvent();
        const dialogRef = this.dialog.open(RetirementFundComponent,
            {
                data: retirementFundEvent
            });

        dialogRef.afterClosed().subscribe(result =>
        {
            if (result)
            {
                this.lifeProjectionService.AddIncomeEvent(retirementFundEvent);
            }
        });
    }

    AddRentalIncome()
    {
        var rentalIncomeEvent = new RentalIncomeEvent();
        const dialogRef = this.dialog.open(RentalIncomeComponent,
            {
                data: rentalIncomeEvent
            });

        dialogRef.afterClosed().subscribe(result =>
        {
            if (result)
            {
                this.lifeProjectionService.AddIncomeEvent(rentalIncomeEvent);
            }
        });
    }

    AddRetirementIncome()
    {
        var retirementIncomeEvent = new RetirementIncomeEvent();
        const dialogRef = this.dialog.open(RetirementIncomeComponent,
            {
                data: retirementIncomeEvent
            });

        dialogRef.afterClosed().subscribe(result =>
        {
            if (result)
            {
                this.lifeProjectionService.AddIncomeEvent(retirementIncomeEvent);
            }
        });
    }

    AddFutureGoal()
    {
        var futureGoalEvent = new FutureGoalEvent();
        const dialogRef = this.dialog.open(FutureGoalComponent,
            {
                data: futureGoalEvent
            });

        dialogRef.afterClosed().subscribe(result =>
        {
            if (result)
            {
                this.lifeProjectionService.AddFutureGoal(futureGoalEvent);
            }
        });
    }
}