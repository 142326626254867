import { Injectable } from "@angular/core";
import { FinancialInformation, FutureGoalEvent, IncomeEventBase, IncomeEventType, SavingsEvent } from "src/DataModel/FinancialInformation";
import { EventEmitter } from "@angular/core";
import { DocumentService } from "./DocumentService";

export class YearState
{
    Age: number;
    Year: number;
    YearsFromToday: number;
    Assets: any;
    Goals: string[];
}

@Injectable()
export class LifeProjectionService
{
    yearStateList: YearState[];
    public onUpdate: EventEmitter<YearState[]>;
    public financialInformation: FinancialInformation
    public isInitialized: boolean;
    isDirty: boolean;

    constructor(public documentService: DocumentService)
    {
        this.yearStateList = [];
        this.onUpdate = new EventEmitter<YearState[]>();

        setInterval((() => 
        {
            this.SaveIfDirty();
        }).bind(this), 5000);
    }

    public async Initialize()
    {
        return new Promise(((resolve, reject) =>
        {
            this.documentService.GetUserFinancialInformationCollection()
                .subscribe(data =>
                {
                    if (!this.isInitialized)
                    {
                        this.financialInformation = new FinancialInformation();
                        if (data.length != 0)
                        {
                            var serializedData = data[0].payload.doc.data()["data"];
                            this.financialInformation.Deserialize(serializedData);
                        }

                        this.Recalculate();
                        this.isInitialized = true;
                    }
                    resolve();
                })
        }).bind(this))
    }

    OnModify()
    {
        this.Recalculate();
        this.isDirty = true;
    }

    AddIncomeEvent(incomeEventBase: IncomeEventBase)
    {
        this.financialInformation.IncomeEvents.push(incomeEventBase);
        this.OnModify();
    }

    AddFutureGoal(incomeEventBase: IncomeEventBase)
    {
        this.financialInformation.FutureGoals.push(incomeEventBase);
        this.OnModify();
    }

    private Recalculate()
    {
        this.yearStateList = [];
        var currentYear = new Date().getFullYear();
        var currentAge = currentYear - this.financialInformation.BirthYear;
        for (let year = currentYear; year < currentYear + 100 - currentAge; year++)
        {
            var yearState = new YearState();
            yearState.Year = year;
            yearState.YearsFromToday = year - currentYear;
            yearState.Age = yearState.Year - this.financialInformation.BirthYear;
            yearState.Assets = 0;
            yearState.Goals = [];

            this.yearStateList.push(yearState);
        }

        this.CalculateAssets();

        this.CalculateFutureGoals();

        this.onUpdate.emit(this.yearStateList);
    }

    CalculateFutureGoals()
    {
        for (let incomeEvent of this.financialInformation.FutureGoals)
        {
            switch (incomeEvent.GetType())
            {
                case IncomeEventType.FutureGoal:
                    this.CalculateFutureGoal(incomeEvent as FutureGoalEvent)
                    break;
            }
        }
    }

    CalculateFutureGoal(futureGoal: FutureGoalEvent)
    {
        for (let index = 0; index < this.yearStateList.length; index++)
        {
            var yearState = this.yearStateList[index];
            if (yearState.Year >= futureGoal.Year)
            {
                yearState.Assets -= futureGoal.CurrentValue;
                if (yearState.Year == futureGoal.Year)
                {
                    yearState.Goals.push(futureGoal.Goal);
                }
            }
        }
    }

    CalculateAssets()
    {
        for (let incomeEvent of this.financialInformation.IncomeEvents)
        {
            switch (incomeEvent.GetType())
            {
                case IncomeEventType.RentalIncome:
                    // var rentalIncomeEvent: RentalIncomeEvent = incomeEvent as RentalIncomeEvent;
                    // if (rentalIncomeEvent.StartingYear >= yearState.Year)
                    // {
                    //     var val = rentalIncomeEvent.AnnualRent
                    // }
                    break;

                case IncomeEventType.Savings:
                    this.CalculateSavingEventAssets(incomeEvent as SavingsEvent)
                    break;
            }
        }
    }

    CalculateSavingEventAssets(savingsEvent: SavingsEvent)
    {
        var totalSavingsLastYear = savingsEvent.CurrentSavings;

        for (let index = 0; index < this.yearStateList.length; index++)
        {
            var yearState = this.yearStateList[index];

            var savingsIncrement = 0;
            if (yearState.Age <= this.financialInformation.ExpectedRetireAge)
            {
                var interest = totalSavingsLastYear * savingsEvent.AverageYearlyReturn / 100.0;
                var savings = savingsEvent.AnnualSavings * Math.pow(1 + savingsEvent.YearlySavingIncrease / 100.0, index);

                savingsIncrement = interest + savings;
            }

            yearState.Assets += totalSavingsLastYear + savingsIncrement;
            totalSavingsLastYear += savingsIncrement;

            // console.log(`Savings: year: ${index}, interest: ${interest} savings: ${savings}, totalSavingsLastYear: ${totalSavingsLastYear}`);            
        }
    }

    RemoveIncomeEvent(incomeEvent: IncomeEventBase)
    {
        var incomeEvents = this.financialInformation.IncomeEvents;
        var index = incomeEvents.indexOf(incomeEvent);
        if (index !== -1)
        {
            incomeEvents.splice(index, 1);
        }
    }

    RemoveFutureGoal(futureGoalEvent: IncomeEventBase)
    {
        var futureGoals = this.financialInformation.FutureGoals;
        var index = futureGoals.indexOf(futureGoalEvent);
        if (index !== -1)
        {
            futureGoals.splice(index, 1);
        }
    }

    SaveIfDirty()
    {
        if (this.isDirty)
        {
            this.documentService.SaveDocument(this.financialInformation);
            this.isDirty = false;
        }
    }
}