import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from '@angular/router';

import { GoogleAuthProvider } from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { getAuth, onAuthStateChanged } from "firebase/auth";

@Injectable()
export class AuthService
{
    private BASE_URL = '/';
    public static readonly NAME_KEY = 'name';
    public static readonly EMAIL_KEY = 'email';
    public static readonly PHOTO_KEY = 'photo';
    public static readonly COLOR_KEY = 'color';
    public static readonly ROLE_KEY = 'role';
    public static readonly TOKEN_KEY = 'token'

    public isAuthenticated: boolean;

    constructor(private http: HttpClient, private router: Router,
        private afAuth: AngularFireAuth, private firestore: AngularFirestore)
    {
        getAuth().onAuthStateChanged((user) =>
        {
            if (user)
            {
                this.isAuthenticated = true;
                console.log('User is logged in');
            }
            else
            {
                this.isAuthenticated = false;
                console.log('User is not logged in');
                this.router.navigate(['/login']);
            }
        });
    }

    GoogleAuth() 
    {
        return this.AuthLogin(new GoogleAuthProvider());
    }
    
    get Color()
    {
        return '333333';
    }

    get Photo()
    {
        const auth = getAuth();
        return auth.currentUser?.photoURL;
    }

    get Name()
    {
        const auth = getAuth();
        return auth.currentUser.displayName;
    }

    async OnLoginSuccess()
    {
        this.isAuthenticated = true;
        const auth = getAuth();

        this.firestore
            .collection("users", ref => ref.where('uid', '==', auth.currentUser.uid))
            .get()
            .subscribe(result =>
            {
                if (result.empty)
                {
                    this.AddNewUserRecord();
                }
                else
                {
                    this.router.navigate(['/manage']);
                }
            });
    }

    AddNewUserRecord()
    {
        const auth = getAuth();
        return this.firestore
            .collection("users")
            .add({
                displayName: auth.currentUser.displayName,
                uid: auth.currentUser.uid
            })
            .then(res =>
            {
                this.router.navigate(['/manage']);
            },
                err => this.OnError(err));
    }

    OnError(err)
    {

    }
    // Auth logic to run auth providers
    AuthLogin(provider)
    {
        return this.afAuth
            .signInWithPopup(provider)
            .then((result) => this.OnLoginSuccess()
            )
            .catch((error) => 
            {
                this.isAuthenticated = false;
                console.log(error);
            });
    }

    Logout()
    {
        return getAuth().signOut();
    }
}