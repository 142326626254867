import { EventEmitter, Input, OnInit } from '@angular/core';
import { forwardRef } from '@angular/core';
import { Component } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, } from '@angular/forms';
import { IFormFieldComponent, NumberField } from 'src/Dialog/dialog.base.component';


@Component({
  selector: 'number-field',
  templateUrl: `./number.field.component.html`,
  providers: [
    { 
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberFieldComponent),
      multi: true
    }
  ]
}
)
export class NumberFieldComponent implements IFormFieldComponent<number>, OnInit
{
  @Input() formField: NumberField;
  formControl: FormControl;

  constructor()
  {
  }

  ngOnInit()
  {
    this.formField.RegisterFormFieldComponent(this);
    this.formControl = new FormControl(this.formField.initialValue, this.formField.validators);
  }

  IsValid(): boolean
  {
    return !this.formControl.invalid;
  }

  MarkAllAsTouched() 
  {
    this.formControl.markAllAsTouched();
  }

  get Value()
  {
    return this.formControl.value;
  }

  public getErrorMessage()
  {
    return this.formControl?.invalid ? this.formControl.errors.message : '';
  }

  onValueChange(value: number)
  {  
    if (this.formField.valueChanged != null)
    {
      this.formField.valueChanged.emit(value);
    }
  }
}

