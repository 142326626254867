import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from '../Home/homepage.component';
import { SignupComponent } from '../User/signup.component';
import { AuthGuard } from '../User/AuthGuard';
import { LoginComponent } from '../User/login.component';
import { MyAssetsComponent } from '../FinancialState/my.assets.component';
import { FinancialStateComponent } from '../FinancialState/financial.state.component';
import { LifeProjectionComponent } from 'src/LifeProjection/life.projection.component';
import { ManageComponent } from 'src/User/Manage.component';
import { CalcComponent } from 'src/Home/calc.component';

const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'signup', component: SignupComponent },
    { path: 'financialstate', component: FinancialStateComponent, canActivate: [AuthGuard] },
    { path: 'myassets', component: MyAssetsComponent, canActivate: [AuthGuard] },
    { path: 'manage', component: ManageComponent, canActivate: [AuthGuard] },
    { path: 'lifeprojection', component: LifeProjectionComponent, canActivate: [AuthGuard] },
    { path: 'calc', component: CalcComponent },
    { path: '**', component: LoginComponent },
    // { path: 'manage', component: ManageComponent, canActivate: [AuthGuard] },
    // { path: '**', component: PageNotFoundComponent }

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
