<button mat-icon-button [matMenuTriggerFor]="menu" class="mx-2">
    <div class="rounded-circle ">
        <img *ngIf="authService.Photo != 'undefined'" class="img-size-40px rounded-circle" src="{{authService.Photo}}" />
        <div *ngIf="authService.Photo == 'undefined'"
            class="width-40px height-40px rounded-circle text-center text-size-20px font-color-white"
            [ngStyle]="{'background': '#' + authService.Color}">
            {{authService.Name[0]}}
        </div>
    </div>
</button>
<mat-menu #menu="matMenu">
    <button mat-menu-item (click)="Logout()">Logout</button>
</mat-menu>