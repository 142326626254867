<mat-card  class="my-1" style="border: 1px solid rgb(247, 203, 9)">
    <mat-card-title>
        <span>{{Goal}}</span>
        <span style="float: right;">
            <a (click)="Remove()" href="javscript:void(0)">
                <mat-icon class="w-50">delete</mat-icon>
            </a>
        </span>
        <span style="float: right;">
            <a (click)="Edit()" href="javscript:void(0)">
                <mat-icon class="w-50">edit</mat-icon>
            </a>
        </span>
    </mat-card-title>
    <mat-card-content>
        <div class="row">
            <div class="col">
                <mat-icon>sports_score</mat-icon>
            </div>
            <div class="col">{{SummaryText}}</div>
        </div>
    </mat-card-content>
</mat-card>