import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from '../Home/homepage.component'
import { FooterComponent } from '../Home/footer.component'
import { NavigationBarComponent } from '../Home/navigationbar.component'
import { SignupComponent } from '../User/signup.component'
import { AuthService } from '../User/auth.service'
import { AuthGuard } from '../User/AuthGuard';
import { LoginComponent } from '../User/login.component';
import { TokenInterceptor } from '../User/AuthInterceptor';
import { GlobalErrorHandler } from './GlobalErrorHandler';
import { GlossaryService } from '../Services/GlossaryService';
import { SeoService } from '../Services/SeoService';
import { ProfileButtonComponent } from 'src/User/ProfileButton.component';
import { APP_BASE_HREF } from '@angular/common';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MyAssetsComponent } from 'src/FinancialState/my.assets.component';
import { DocumentService } from 'src/Services/DocumentService';
import { HouseComponent } from 'src/FinancialState/house.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FinancialStateComponent } from 'src/FinancialState/financial.state.component';
import { NavigationComponent } from 'src/FinancialState/navigation.component';
import { LifeProjectionComponent } from 'src/LifeProjection/life.projection.component';
import { SavingsSummaryComponent } from 'src/LifeProjection/savings.summary.component';
import { SavingsComponent } from 'src/LifeProjection/savings.component';
import { RetirementFundSummaryComponent } from 'src/LifeProjection/retirement.fund.summary.component';
import { RetirementFundComponent } from 'src/LifeProjection/retirement.fund.component';
import { RentalIncomeSummaryComponent } from 'src/LifeProjection/rental.income.summary.component';
import { RentalIncomeComponent } from 'src/LifeProjection/rental.income.component';
import { RetirementIncomeComponent } from 'src/LifeProjection/retirement.income.component';
import { RetirementIncomeSummaryComponent } from 'src/LifeProjection/retirement.income.summary.component';
import { FutureGoalComponent } from 'src/LifeProjection/future.goal.component';
import { FutureGoalSummaryComponent } from 'src/LifeProjection/future.goal.summary.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { LifeChartComponent } from 'src/LifeProjection/life.chart.component';

import { SliderFieldComponent } from 'src/Dialog/slider.field.component';
import { NumberFieldComponent } from 'src/Dialog/number.field.component';
import { StringFieldComponent } from 'src/Dialog/string.field.component';
import { LifeProjectionService } from 'src/Services/LifeProjectionService';
import { NgxSpinnerModule } from "ngx-spinner";
import { ManageComponent } from 'src/User/Manage.component';
import { CalcComponent } from 'src/Home/calc.component';

@NgModule({
	declarations: [
		AppComponent,
		NavigationBarComponent, FooterComponent,
		HomePageComponent, SignupComponent, LoginComponent, ProfileButtonComponent, ManageComponent,
		MyAssetsComponent, HouseComponent, FinancialStateComponent, NavigationComponent,
		LifeProjectionComponent, LifeChartComponent, SavingsSummaryComponent,SavingsComponent, RetirementFundSummaryComponent, RetirementFundComponent,
		RentalIncomeSummaryComponent, RentalIncomeComponent, RetirementIncomeComponent, RetirementIncomeSummaryComponent,
		FutureGoalComponent, FutureGoalSummaryComponent,
		NumberFieldComponent, StringFieldComponent, SliderFieldComponent,
		CalcComponent
	],
	imports: [
		AngularFireModule.initializeApp(environment.firebase),
		AngularFireAuthModule,
        AngularFirestoreModule,
		BrowserModule,
		HttpClientModule,
		AppRoutingModule,
		FormsModule, ReactiveFormsModule,
		BrowserAnimationsModule,
		MatCardModule, MatMenuModule, MatIconModule, MatDividerModule, MatButtonModule, MatFormFieldModule, MatInputModule, MatSelectModule,
		MatCheckboxModule, MatSidenavModule,MatListModule, MatSliderModule, MatTableModule,
		MatToolbarModule,MatGridListModule,MatDialogModule,
		NgbModule,
		NgxEchartsModule.forRoot({
			echarts: () => import('echarts')
		}),
        NgxSpinnerModule
	],

	providers: [
		HttpClient, HttpClientModule,
		AuthService, AuthGuard, TokenInterceptor, GlossaryService, SeoService, DocumentService, LifeProjectionService,
		[
			{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
			{ provide: ErrorHandler, useClass: GlobalErrorHandler }
		],
		{ provide: APP_BASE_HREF, useValue: '/' },
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
