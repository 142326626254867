import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from './auth.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { GlossaryService } from '../Services/GlossaryService';
import { SeoService } from '../Services/SeoService';
import { ValidatorUtil } from '../Util/ValidationUtil';
import { getAuth } from 'firebase/auth';

@Component({
	selector: 'login',
	templateUrl: 'login.component.html',
	styles: [`
        .error {
            background-color: #ffaeae
        }
    `]
})
export class LoginComponent
{
	errorMessage: string;
	hasErrors: boolean;
	form: FormGroup;

	constructor(private fb: FormBuilder, public authService: AuthService, private title: Title, public activatedRoute: ActivatedRoute,
		public glossaryService: GlossaryService, private seoService: SeoService, private router: Router,)
	{
		this.form = fb.group({
			Email: new FormControl('', [Validators.required, ValidatorUtil.ValidateEmailControl()]),
			Password: new FormControl('', Validators.required),
		});

		title.setTitle(`Log in to ${glossaryService.ProductName}`);

		seoService.SetCanonicalURL("/login");
	}

	ngOnInit()
	{
        getAuth().onAuthStateChanged((user) =>
        {
            if (user)
            {
                this.router.navigate(['/manage']);
                console.log('User is logged in');
            }
        });
	}

	showErrors()
	{
		for (let ctrlName in this.form.controls)
		{
			let ctrl: FormControl = <FormControl>this.form.controls[ctrlName];
			if (!ctrl.valid)
			{
				if (ctrl.errors != null)
				{
					if (ctrl.errors.required)
					{
						this.hasErrors = true;
						this.errorMessage = "Please fill all required fields";
						break;
					}
					else if (ctrl.errors.invalidEmail)
					{
						this.hasErrors = true;
						this.errorMessage = "Please enter a valid email address";
						break;
					}
				}
			}
		}
	}

	onSubmit() 
	{
		// if (this.form.valid)
		// {
		// 	this.auth.login(this.form.value, (error =>
		// 	{
		// 		this.hasErrors = true;
		// 		this.errorMessage = error.error.message;
		// 	}).bind(this));
		// }
		// else
		// {
		// 	this.showErrors();
		// }
	}

	isValid(control)
	{
		return this.form.controls[control].invalid && this.form.controls[control].touched
	}
}
