import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FutureGoalEvent, SavingsEvent } from 'src/DataModel/FinancialInformation';
import { ValidatorUtil } from 'src/Util/ValidationUtil';
import { DialogBaseComponent, NumberField, StringField } from '../Dialog/dialog.base.component';
import { FutureGoalSummaryComponent } from './future.goal.summary.component';

@Component({
  selector: 'future-goal-component',
  templateUrl: 'future.goal.component.html',
})
export class FutureGoalComponent extends DialogBaseComponent{
  futureGoalEvent: FutureGoalEvent;
  goalControl: StringField;
  yearControl: NumberField;
  currentValueControl: NumberField;

  constructor(
    public dialogRef: MatDialogRef<FutureGoalSummaryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FutureGoalEvent)
  {
    super(dialogRef);

    this.futureGoalEvent = data;

    this.goalControl = this.AddStringField("Goal", this.futureGoalEvent.Goal, [ValidatorUtil.ValidateRequired()]);
    this.yearControl = this.AddNumberField("Year", this.futureGoalEvent.Year, [ValidatorUtil.ValidateRequired()]);
    this.currentValueControl = this.AddNumberField("Current Value", this.futureGoalEvent.CurrentValue, [ValidatorUtil.ValidateRequired()]);
  }

  ngOnInit() 
  {
    this.dialogRef.updateSize('650px', 'auto');
  }

  OnSaveSuccess()
  {
    this.futureGoalEvent.Goal = this.goalControl.Value;
    this.futureGoalEvent.Year = this.yearControl.Value;
    this.futureGoalEvent.CurrentValue = this.currentValueControl.Value;
  }
}
