<nav class="navbar navbar-light navbar-expand-md justify-content-between" style="background-color: #6db0f3!important;">
	<a href="/" class="navbar-brand mr-0 ml-2">
		<img src="assets/img/Logo.svg" style="height:50px" />
		<span class="font-family-default font-color-orange font-size-36px vertical-align-middle ml-1">Wealthly</span>
	</a>
	<button class="navbar-toggler ml-1" type="button" data-toggle="collapse" data-target="#collapsingNavbar2"
		style="text-align: left;" aria-expanded="true">
		<span class="navbar-toggler-icon"></span>
	</button>
	<div class="navbar-collapse justify-content-between align-items-center w-100 collapse" id="collapsingNavbar2">
		<span style="width:100px"></span>
		<ul class="nav navbar-nav flex-row justify-content-center flex-nowrap">
			<li class="nav-item">
				<a class="btn btn-primary font-family-default" href="/signup" title="Sign up with {{glossaryService.ProductName}}">Sign Up</a>
			</li>
			<li class="nav-item ml-2">
				<a class="nav-link" href="/login" title="Login to {{glossaryService.ProductName}}">Login</a>
			</li>
		</ul>
	</div>
</nav>