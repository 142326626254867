import { Component } from '@angular/core';
import { ApartmentAsset, AssetType, FinancialInformation, HouseAsset } from 'src/DataModel/FinancialInformation';
import { DocumentService } from '../Services/DocumentService';
import { GlossaryService } from '../Services/GlossaryService';


@Component({
  selector: 'my-assets',
  templateUrl: `./my.assets.component.html`
})
export class MyAssetsComponent 
{
    public AssetType = AssetType;
    public financialInformation: FinancialInformation;
	constructor(public glossaryService: GlossaryService, private documentService: DocumentService)
	{
        // this.financialInformation = this.documentService.GetFinancialInformation();
    }

    AddHouse()
    {
        // this.financialInformation.Assets.PersonalAssets.Items.push(new HouseAsset());
    }

    AddApartment()
    {
        // this.financialInformation.Assets.PersonalAssets.Items.push(new ApartmentAsset());
    }

}