import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RentalIncomeEvent } from 'src/DataModel/FinancialInformation';
import { ValidatorUtil } from 'src/Util/ValidationUtil';
import { DialogBaseComponent, NumberField, SliderField } from '../Dialog/dialog.base.component';

@Component({
  selector: 'rental-income-component',
  templateUrl: 'rental.income.component.html',
})
export class RentalIncomeComponent extends DialogBaseComponent{
  rentalIncomeEvent: RentalIncomeEvent;
  annualRentControl: NumberField;
  yearlyIncreaseControl: SliderField;
  startingYearControl: NumberField;

  constructor(
    public dialogRef: MatDialogRef<RentalIncomeEvent>,
    @Inject(MAT_DIALOG_DATA) public data: RentalIncomeEvent)
  {
    super(dialogRef);

    this.rentalIncomeEvent = data;

    this.annualRentControl = this.AddNumberField("Annual Rent", this.rentalIncomeEvent.AnnualRent, [ValidatorUtil.ValidateRequired()]);
    this.yearlyIncreaseControl = this.AddSliderField("Yearly Increase (%)", this.rentalIncomeEvent.YearlyIncrease, 
      0, 100, 0.5, 1, false, []);
    this.startingYearControl = this.AddNumberField("Starting Year", this.rentalIncomeEvent.StartingYear,
      [ValidatorUtil.ValidateMinMax(1980, 2100), ValidatorUtil.ValidateRequired()]);
  }

  ngOnInit() 
  {
    this.dialogRef.updateSize('325px', 'auto');
  }

  OnSaveSuccess()
  {
    this.rentalIncomeEvent.AnnualRent = this.annualRentControl.Value;
    this.rentalIncomeEvent.YearlyIncrease = this.yearlyIncreaseControl.Value;
    this.rentalIncomeEvent.StartingYear = this.startingYearControl.Value;
  }
}
