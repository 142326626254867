import { Component, OnInit } from '@angular/core';
import { LifeProjectionService, YearState } from 'src/Services/LifeProjectionService';

@Component({
    selector: 'life-chart',
    templateUrl: './life.chart.component.html'
})
export class LifeChartComponent implements OnInit
{
    options: any;
    yearStateList: YearState[]

    constructor(public lifeProjectionService: LifeProjectionService) 
    {
        this.lifeProjectionService.onUpdate.subscribe(yearStateList => this.OnUpdate(yearStateList))
        this.OnUpdate(lifeProjectionService.yearStateList);
    }

    OnUpdate(yearStateList: YearState[]): void
    {
        this.yearStateList = yearStateList;
        var xAxisData = [];
        var yAxisData = [];
        var goalData = [];
        for (let index = 0; index < yearStateList.length; index++)
        {
            var yearState = yearStateList[index];
            xAxisData.push(yearState.Age);
            yAxisData.push(yearState.Assets);

            for (let goal of yearState.Goals)
            {
                goalData.push([index, yearState.Assets, goal]);
            }
        }

        this.UpdateChart(xAxisData, yAxisData, goalData);
    }

    UpdateChart(xAxisData: number[], yAxisData: number[], goalData: [][])
    {
        this.options = {
            xAxis: {
                name: 'Age',
                type: 'category',
                boundaryGap: false,
                data: xAxisData
            },
            yAxis: {
                name: 'Assets',
                type: 'value'
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        formatter: function (params: any)
                        {
                            if (params.axisDimension == 'y')
                            {
                                return '$' + Math.round(params.value).toLocaleString();
                            }
                            return `Age: ${params.value}`;
                        }
                    }
                },
                formatter: params => this.getToolTip(params),                
                snap: true,
            },
            series: [
                {
                    name: "Average",
                    data: yAxisData,
                    type: 'line',
                    areaStyle: {},
                },
                {
                    name: "Goals",
                    symbolSize: 20,
                    data: goalData,
                    type: 'scatter',
                    areaStyle: {}
                }
            ]
        };
    }

    getToolTip(params: any)
    {
        console.log(params);
        var age = null;
        var assets = null;
        var goals = "";
        for (let param of params)
        {
            age = param.axisValue;
            if (param.seriesIndex == 0)
            {
                assets = Math.round(param.value).toLocaleString();
            }
            else if (param.seriesIndex == 1)
            {   
                // var currentYear = new Date().getFullYear();
                // var index = param.axisValue - (currentYear - this.lifeProjectionService.financialInformation.BirthYear);
                var goal = param.data[2];
                goals += `<div style="margin: 0px 0 0;line-height:1;">
                            <div style="margin: 10px 0 0;line-height:1;">
                                <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#91cc75;"></span>
                                <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">Goal</span>
                                <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${goal}</span>
                                <div style="clear:both"></div>
                            </div>
                            <div style="clear:both"></div>
                        </div>`;
            }
        }
        var html = `<div style="margin: 0px 0 0;line-height:1;">
            <div style="margin: 0px 0 0;line-height:1;">
                <div style="font-size:14px;color:#666;font-weight:400;line-height:1;">Age ${age}</div>
                <div style="margin: 10px 0 0;line-height:1;">
                    <div style="margin: 0px 0 0;line-height:1;">
                        <div style="margin: 0px 0 0;line-height:1;">
                            <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#5470c6;"></span>
                            <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">Assets</span>
                            <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">S${assets}</span>
                            <div style="clear:both"></div>
                        </div>
                        <div style="clear:both"></div>
                    </div>
                    ${goals}
                    <div style="clear:both"></div>
                </div>
                <div style="clear:both"></div>
            </div>
            <div style="clear:both"></div>
        </div>`
        return html;
    }

    ngOnInit(): void
    {
     
    }
}