<h1 mat-dialog-title>Future Goal</h1>
<div mat-dialog-content>
    <div class="row">
        <div class="col">

            <string-field class="row" [formField]='goalControl' ></string-field>
            <number-field class="row" [formField]='yearControl' ></number-field>
            <number-field class="row" [formField]='currentValueControl' ></number-field>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="accent" (click)="onSaveClick()" cdkFocusInitial>Save</button>
    <button mat-raised-button (click)="onDiscardClick()">Discard</button>
</div>