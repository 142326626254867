import { EventEmitter } from "@angular/core";
import { ValidatorFn } from "@angular/forms";

export abstract class DialogBaseComponent 
{
  formFields: IFormField[] = [];

  constructor(public dialogRef)
  {
  }

  private AddField<T>(formField: FormField<T>, label: string, initialValue: T, validators: ValidatorFn[])
  {
    formField.label = label;
    formField.initialValue = initialValue;
    formField.validators = validators;
    this.formFields.push(formField);    
  }

  AddNumberField(label: string, initialValue: number, validators: ValidatorFn[]): NumberField {
    var numberField = new NumberField();
    this.AddField(numberField, label, initialValue, validators);

    return numberField;
  }

  AddStringField(label: string, initialValue: string, validators: ValidatorFn[]): StringField {
    var stringField = new StringField();
    this.AddField(stringField, label, initialValue, validators);

    return stringField;
  }  

  AddSliderField(label: string, initialValue: number, min: number, max: number, step: number, decimals: number, showTicks: boolean, validators: ValidatorFn[]): SliderField 
  {
    var sliderField = new SliderField();
    sliderField.Min = min;
    sliderField.Max = max;
    sliderField.Step = step;
    sliderField.Decimals = decimals;
    sliderField.ShowTicks = showTicks;
    
    this.AddField(sliderField, label, initialValue, validators);
    return sliderField;
  }

  abstract OnSaveSuccess();

  onSaveClick(): void 
  {
    for (var formField of this.formFields)		
    {
      if (!formField.IsValid())
      {
        formField.MarkAllAsTouched();
        return;
      }
    }

    this.OnSaveSuccess();
    this.dialogRef.close(true);
  }

  onDiscardClick(): void 
  {
    this.dialogRef.close();
  }
}

export interface IFormFieldComponent<T>
{
  IsValid() : boolean;
  MarkAllAsTouched();
  Value: T;
}

export interface IFormField
{
  IsValid() : boolean;
  MarkAllAsTouched();
}

export class FormField<T> implements IFormField
{
  public label: string;
  public validators: ValidatorFn[];
  public initialValue: T;
  valueChanged: EventEmitter<number>;

  constructor()
  {
    this.valueChanged = new EventEmitter<number>();
  }
  
  private formFieldComponent: IFormFieldComponent<T>;
  
  public RegisterFormFieldComponent(formFieldComponent: IFormFieldComponent<T>)
  {
    this.formFieldComponent = formFieldComponent;
  }

  public IsValid() : boolean
  {
    return this.formFieldComponent?.IsValid();
  }
 
  public MarkAllAsTouched()
  {
    this.formFieldComponent?.MarkAllAsTouched();
  }

  public get Value(): T
  {
    return this.formFieldComponent.Value;
  }
}

export class NumberField extends FormField<number>
{
}

export class StringField extends FormField<string>
{
}

export class SliderField extends FormField<number>
{
  public ShowTicks: boolean;
  public Min: number;
  public Max: number;
  public Step: number;
  public Decimals: number;
}


