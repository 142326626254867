import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "../User/auth.service";
import { FinancialInformation } from "src/DataModel/FinancialInformation";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { getAuth } from "firebase/auth";
import { firstValueFrom } from "rxjs";

@Injectable()
export class DocumentService
{
	BASE_URL = '/';
	constructor(private http: HttpClient, private router: Router, private auth: AuthService,
		private firestore: AngularFirestore) { }

	public GetUserFinancialInformationCollection()
	{
		const auth = getAuth()
		return this.firestore
			.collection("life_projection", ref => ref.where('uid', '==', auth.currentUser.uid))
			.snapshotChanges();
	}

	async SaveDocument(financialInformation: FinancialInformation)
	{
		const auth = getAuth()

		var data = {};
		financialInformation.Serialize(data);

		var documentRef = await this.firestore
			.collection("life_projection", ref => ref.where('uid', '==', auth.currentUser.uid));

		var queryResult = await firstValueFrom(documentRef.get());
		if (queryResult.docs.length > 0)
		{
			var docRef = queryResult.docs[0].ref;
			docRef.update({ data: data });
		}
		else
		{
			await this.firestore
				.collection("life_projection")
				.add({
					uid: auth.currentUser.uid,
					data: data
				});
		}

	}

	OnError(err: any): any
	{
		throw new Error("Method not implemented.");
	}
}