import { Input } from '@angular/core';
import { Component } from '@angular/core';
import { HouseAsset } from 'src/DataModel/FinancialInformation';
import { DocumentService } from '../Services/DocumentService';
import { GlossaryService } from '../Services/GlossaryService';


@Component({
  selector: 'financial-state',
  templateUrl: `./financial.state.component.html`
})
export class FinancialStateComponent 
{    
	constructor()
	{
    }
}