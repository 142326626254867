<h1 mat-dialog-title>Savings & Investments</h1>
<div mat-dialog-content>
    <div class="row">
        <div class="col">

            <number-field class="row" [formField]='currentSavingsControl' ></number-field>
            <number-field class="row" [formField]='annualSavingsControl' ></number-field>
        </div>
        <div class="col">
            <number-field class="row" [formField]='yearlySavingsIncreaseControl' ></number-field>
            <!-- <number-field class="row" [field]='averageYearlyReturnControl' ></number-field> -->
            <slider-field class="row" [formField]='averageYearlyReturnControl' ></slider-field>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="accent" (click)="onSaveClick()" cdkFocusInitial>Save</button>
    <button mat-raised-button (click)="onDiscardClick()">Discard</button>
</div>