// import { Options } from '@angular-slider/ngx-slider';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SavingsEvent } from 'src/DataModel/FinancialInformation';
import { ValidatorUtil } from 'src/Util/ValidationUtil';
import { DialogBaseComponent, NumberField, SliderField } from '../Dialog/dialog.base.component';
import { SavingsSummaryComponent } from './savings.summary.component';

@Component({
  selector: 'savings-component',
  templateUrl: 'savings.component.html',
})
export class SavingsComponent extends DialogBaseComponent{
  savingsEvent: SavingsEvent;
  averageYearlyReturnControl: SliderField;
  yearlySavingsIncreaseControl: NumberField;
  currentSavingsControl: NumberField;
  annualSavingsControl: NumberField;

  value: number=100;


  constructor(
    public dialogRef: MatDialogRef<SavingsSummaryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SavingsEvent)
  {
    super(dialogRef);

    this.savingsEvent = data;

    this.currentSavingsControl = this.AddNumberField("Current Savings", this.savingsEvent.CurrentSavings, [ValidatorUtil.ValidateRequired()]);
    this.annualSavingsControl = this.AddNumberField("Annual Savings", this.savingsEvent.AnnualSavings, [ValidatorUtil.ValidateRequired()]);
    this.yearlySavingsIncreaseControl = this.AddNumberField("Yearly Savings Increase/Decrease", this.savingsEvent.YearlySavingIncrease, [ValidatorUtil.ValidateRequired()]);
    this.averageYearlyReturnControl = this.AddSliderField("Average Yearly Return (%)", this.savingsEvent.AverageYearlyReturn,
      0, 100, 0.5, 1, false,
      [ValidatorUtil.ValidateMinMax(0, 100), ValidatorUtil.ValidateRequired()]);
  }

  ngOnInit() 
  {
    this.dialogRef.updateSize('650px', 'auto');
  }

  OnSaveSuccess()
  {
    this.savingsEvent.CurrentSavings = this.currentSavingsControl.Value;
    this.savingsEvent.AnnualSavings = this.annualSavingsControl.Value;
    this.savingsEvent.YearlySavingIncrease = this.yearlySavingsIncreaseControl.Value;
    this.savingsEvent.AverageYearlyReturn = this.averageYearlyReturnControl.Value;
  }
}
