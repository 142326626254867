import { Injectable } from "@angular/core";

@Injectable()
export class GlossaryService
{
	public get ProductName()
	{
		return "Wealthly";
	}

	public get BaseUrl()
	{
		return "https://www.wealthly.lk";
	}
}