import { Input } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SavingsEvent } from 'src/DataModel/FinancialInformation';
import { LifeProjectionService } from 'src/Services/LifeProjectionService';
import { SavingsComponent } from './savings.component';


@Component({
    selector: 'savings-summary',
    templateUrl: `./savings.summary.component.html`
})
export class SavingsSummaryComponent
{
    @Input() savingsEvent: SavingsEvent;
    constructor(public dialog: MatDialog, private lifeProjectionService: LifeProjectionService) 
    {
    }

    public get SummaryText()
    {
        return `$${Math.round(this.savingsEvent.CurrentSavings ?? 0).toLocaleString()} today | $${Math.round(this.savingsEvent.YearlySavingIncrease ?? 0).toLocaleString()} per year`;
    }

    Edit(): void
    {
        const dialogRef = this.dialog.open(SavingsComponent, {
            data: this.savingsEvent,
        });

        dialogRef.afterClosed().subscribe(result =>
        {
            this.lifeProjectionService.OnModify();
        });
    }

    Remove()
    {
        this.lifeProjectionService.RemoveIncomeEvent(this.savingsEvent);
    }
}