import {Component} from '@angular/core';
import { AuthService } from '../User/auth.service';
import { GlossaryService } from '../Services/GlossaryService';

@Component({
        selector: 'navigation-bar2',
        templateUrl: './navigationbar.component.html',
    }
)
export class NavigationBarComponent
{
    constructor(public auth: AuthService, public glossaryService: GlossaryService)
    {
	}
}


