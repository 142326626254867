<meta name="viewport" content="width=520, initial-scale=0.5" id="vwPrt">
<div class="container">
	<div class="row my-2">
		<span>
			<mat-icon class="material-icons color_green">check</mat-icon>
			<label style="min-width: 45px;">{{this.correctAnswers}}</label>
			<mat-icon class="material-icons color_red">close</mat-icon>
			<label style="min-width: 45px;">{{this.incorrectAnswers}}</label>
			<mat-icon class="material-icons color_black">alarm</mat-icon>
			<label style="min-width: 40px;">{{this.seconds}}</label>
		</span>
	</div>

	<div class="row my-3" *ngIf="this.isStarted">
		<span>
			<label style="width: 70px; text-align: right;margin-bottom: 2px;">{{num1}} {{selectedGameConfig.operator}} {{num2}} = </label>
			<label class="border border-dark border-2 mx-1"
				style="width: 50px; height: 25px; vertical-align: bottom;">{{answer}}</label>
		</span>
	</div>
	<div class="row  mt-3" *ngIf="!this.isStarted">
		<span>
			<mat-form-field appearance="fill">
				<mat-select (selectionChange)="SelectGameConfig($event.value)">

					<mat-option *ngFor="let gameConfig of this.gameConfigs" [value]="gameConfig">
						{{gameConfig.name}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</span>
		<button mat-raised-button color="primary" class="mx-1" style="width: 100px;" (click)="Start()">Start</button>
	</div>

	<div class="row my-2">
		<button mat-fab class="mx-1" (click)="OnKey(1)">1</button>
		<button mat-fab class="mx-1" (click)="OnKey(2)">2</button>
		<button mat-fab class="mx-1" (click)="OnKey(3)">3</button>
	</div>
	<div class="row  mt-2">
		<button mat-fab class="mx-1" (click)="OnKey(4)">4</button>
		<button mat-fab class="mx-1" (click)="OnKey(5)">5</button>
		<button mat-fab class="mx-1" (click)="OnKey(6)">6</button>
	</div>
	<div class="row  mt-2">
		<button mat-fab class="mx-1" (click)="OnKey(7)">7</button>
		<button mat-fab class="mx-1" (click)="OnKey(8)">8</button>
		<button mat-fab class="mx-1" (click)="OnKey(9)">9</button>
	</div>
	<div class="row  mt-2">
		<button mat-fab class="mx-1" (click)="OnKey(0)">0</button>
		<button mat-fab class="mx-1" (click)="OnKey(-1)">DEL</button>
		<button mat-fab class="mx-1" (click)="OnKey(-2)"><span
				class="material-icons">subdirectory_arrow_right</span></button>
	</div>

	<div class="row  mt-2">
		<span class="font-weight-bold">Answers<mat-divider class="my-2"></mat-divider></span>
	</div>
	<div class="row my-1" *ngFor="let question of this.questions">
		<span>
			<label style="width: 70px; text-align: right;vertical-align: text-top">{{question.num1}} {{question.operator}} {{question.num2}} =
			</label>
			<label class="border border-dark border-2 mx-1"
				style="width: 50px; height: 25px; vertical-align: bottom;">{{question.answer}}</label>
			<mat-icon *ngIf="question.state" class="material-icons color_green" style="vertical-align: text-top;">check</mat-icon>
			<mat-icon *ngIf="!question.state" class="material-icons color_red" style="vertical-align: text-top;">close</mat-icon>

		</span>
	</div>

	<div class="row  mt-2">
		<span class="font-weight-bold">Top scores<mat-divider class="my-2"></mat-divider></span>
	</div>

	<table mat-table [dataSource]="bestScores" class="mat-elevation-z8">
		<ng-container matColumnDef="correctAnswer">
			<th mat-header-cell *matHeaderCellDef> Correct </th>
			<td mat-cell *matCellDef="let element"> {{element.correctAnswers}} </td>
		  </ng-container>	

		  <ng-container matColumnDef="incorrectAnswer">
			<th mat-header-cell *matHeaderCellDef> Incorrect </th>
			<td mat-cell *matCellDef="let element"> {{element.incorrectAnswers}} </td>
		  </ng-container>
	  
		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
	  </table>
</div>