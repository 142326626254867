import { Component } from "@angular/core";
import { AuthService } from "./auth.service";
import { Router } from "@angular/router";

@Component({
	selector: 'profile-button',
	templateUrl: 'ProfileButton.component.html',
	styles: []
})
export class ProfileButtonComponent
{
	constructor(public authService: AuthService, private router: Router)
	{
	}

	async Logout()
	{
		await this.authService.Logout();
		this.router.navigate(['/login']);
	}
}