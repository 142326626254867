import { FutureGoalComponent } from "src/LifeProjection/future.goal.component";

export class FinancialInformation
{
    constructor()
    {
        var savingEvent: SavingsEvent = new SavingsEvent();
        savingEvent.AnnualSavings = 100000;
        savingEvent.CurrentSavings = 1000000;
        savingEvent.AverageYearlyReturn = 12;
        savingEvent.YearlySavingIncrease = 1;
        this.IncomeEvents.push(savingEvent);

        var futureGoal1 = new FutureGoalEvent();
        futureGoal1.Goal = "Daughter's wedding";
        futureGoal1.Year = 2050;
        futureGoal1.CurrentValue = 4000000;
        this.FutureGoals.push(futureGoal1);

        var futureGoal2 = new FutureGoalEvent();
        futureGoal2.Goal = "Europe trip";
        futureGoal2.Year = 2050;
        futureGoal2.CurrentValue = 1000000;
        this.FutureGoals.push(futureGoal2);

        this.BirthYear = 1980;
        this.ExpectedRetireAge = 60;
    }
    public IncomeEvents: IncomeEventBase[] = [];
    public FutureGoals: IncomeEventBase[] = [];
    public BirthYear: number;
    public ExpectedRetireAge: number;

    Serialize(data)
    {
        data.BirthYear = this.BirthYear;
        data.ExpectedRetireAge = this.ExpectedRetireAge;
        data.IncomeEvents = [];
        for (var incomeEvent of this.IncomeEvents)
        {
            var incomeEventData = {};
            incomeEventData["Type"] = incomeEvent.GetType();
            incomeEvent.Serialize(incomeEventData);
            data.IncomeEvents.push(incomeEventData);
        }

        data.FutureGoals = [];
        for (var futureGoal of this.FutureGoals)
        {
            var futureGoalData = {};
            futureGoalData["Type"] = futureGoal.GetType();
            futureGoal.Serialize(futureGoalData);
            data.FutureGoals.push(futureGoalData);
        }
    }

    Deserialize(data)
    {
        this.BirthYear = data.BirthYear;
        this.ExpectedRetireAge = data.ExpectedRetireAge;
        this.IncomeEvents = [];
        for (var incomeEventData of data.IncomeEvents)
        {
            var incomeEvent = IncomeEventBase.CreateIncomeEvent(incomeEventData.Type);
            incomeEvent.Deserialize(incomeEventData);

            this.IncomeEvents.push(incomeEvent);
        }

        this.FutureGoals = [];
        for (var futureGoalData of data.FutureGoals)
        {
            var futureGoal = IncomeEventBase.CreateIncomeEvent(futureGoalData.Type);
            futureGoal.Deserialize(futureGoalData);

            this.FutureGoals.push(futureGoal);
        }
    }
}

export abstract class IncomeEventBase
{
    abstract Serialize(incomeEventData);

    constructor()
    {
    }
    abstract GetType(): IncomeEventType;

    static CreateIncomeEvent(incomeEventType: IncomeEventType): IncomeEventBase
    {
        switch (incomeEventType)
        {
            case IncomeEventType.Savings:
                return new SavingsEvent();
            case IncomeEventType.RetirementFund:
                return new RetirementFundEvent();
            case IncomeEventType.RentalIncome:
                return new RentalIncomeEvent();
            case IncomeEventType.RetirementIncome:
                return new RetirementIncomeEvent();
            case IncomeEventType.FutureGoal:
                return new FutureGoalEvent();
        }
    }

    abstract Deserialize(data);
}

export enum IncomeEventType
{
    Savings,
    RetirementFund,
    RentalIncome,
    RetirementIncome,
    FutureGoal,
}

export class SavingsEvent extends IncomeEventBase
{
    public CurrentSavings;
    public AnnualSavings;
    public YearlySavingIncrease;
    public AverageYearlyReturn;

    GetType(): IncomeEventType
    {
        return IncomeEventType.Savings;
    }

    Serialize(incomeEventData)
    {
        incomeEventData.CurrentSavings = this.CurrentSavings;
        incomeEventData.AnnualSavings = this.AnnualSavings;
        incomeEventData.YearlySavingIncrease = this.YearlySavingIncrease;
        incomeEventData.AverageYearlyReturn = this.AverageYearlyReturn;
    }

    Deserialize(data)
    {
        this.CurrentSavings = data.CurrentSavings;
        this.AnnualSavings = data.AnnualSavings;
        this.YearlySavingIncrease = data.YearlySavingIncrease;
        this.AverageYearlyReturn = data.AverageYearlyReturn;
    }
}

export class RetirementFundEvent extends IncomeEventBase
{
    public CurrentFundSize;
    public AnnualContribution;
    public AverageYearlyReturn;
    public AnnualFundReturn;

    GetType(): IncomeEventType
    {
        return IncomeEventType.RetirementFund;
    }

    Serialize(incomeEventData)
    {
        incomeEventData.CurrentFundSize = this.CurrentFundSize;
        incomeEventData.AnnualContribution = this.AnnualContribution;
        incomeEventData.AverageYearlyReturn = this.AverageYearlyReturn;
        incomeEventData.AnnualFundReturn = this.AnnualFundReturn;
    }

    Deserialize(data)
    {
        this.CurrentFundSize = data.CurrentFundSize;
        this.AnnualContribution = data.AnnualContribution;
        this.AverageYearlyReturn = data.AverageYearlyReturn;
        this.AnnualFundReturn = data.AnnualFundReturn;
    }
}

export class RetirementIncomeEvent extends IncomeEventBase
{
    public ExpectedAnnualIncome;

    GetType(): IncomeEventType
    {
        return IncomeEventType.RetirementIncome;
    }

    Serialize(incomeEventData)
    {
        incomeEventData.ExpectedAnnualIncome = this.ExpectedAnnualIncome;
    }

    Deserialize(data)
    {
        this.ExpectedAnnualIncome = data.ExpectedAnnualIncome;
    }
}

export class RentalIncomeEvent extends IncomeEventBase
{
    public AnnualRent;
    public YearlyIncrease;
    public StartingYear;

    GetType(): IncomeEventType
    {
        return IncomeEventType.RentalIncome;
    }

    Serialize(incomeEventData)
    {
        incomeEventData.AnnualRent = this.AnnualRent;
        incomeEventData.YearlyIncrease = this.YearlyIncrease;
        incomeEventData.StartingYear = this.StartingYear;
    }

    Deserialize(data)
    {
        this.AnnualRent = data.AnnualRent;
        this.YearlyIncrease = data.YearlyIncrease;
        this.StartingYear = data.StartingYear;
    }
}

export class FutureGoalEvent extends IncomeEventBase
{
    public Year: number;
    public Goal: string;
    public CurrentValue: number;

    GetType(): IncomeEventType
    {
        return IncomeEventType.FutureGoal;
    }

    Serialize(incomeEventData)
    {
        incomeEventData.Year = this.Year;
        incomeEventData.Goal = this.Goal;
        incomeEventData.CurrentValue = this.CurrentValue;
    }

    Deserialize(data)
    {
        this.Year = data.Year;
        this.Goal = data.Goal;
        this.CurrentValue = data.CurrentValue;
    }
}

export class Assets
{
    constructor()
    {
        this.PersonalAssets = new PersonalAssets();
    }
    public PersonalAssets: PersonalAssets;
}

export enum AssetType
{
    House,
    Apartment
}
export abstract class BaseAsset
{
    abstract GetAssetType(): AssetType;
}

export class PersonalAssets
{
    public Items: BaseAsset[] = [];
    constructor()
    {
        this.Items.push(new HouseAsset());
    }
}

export class HouseAsset extends BaseAsset
{
    public SizeInPerches: number;
    public PricePerPerch: number;
    public YearCompleted: number;
    public SalvageValuePercentage: number;
    public DepreciationCompletesInYears: number;

    GetAssetType(): AssetType
    {
        return AssetType.House;
    }
}

export class ApartmentAsset extends BaseAsset
{
    public SizeInPerches: number;
    public PricePerPerch: number;
    public YearCompleted: number;
    public SalvageValuePercentage: number;
    public DepreciationCompletesInYears: number;

    GetAssetType(): AssetType
    {
        return AssetType.Apartment;
    }
}