import { Input } from '@angular/core';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HouseAsset } from 'src/DataModel/FinancialInformation';
import { DocumentService } from '../Services/DocumentService';
import { GlossaryService } from '../Services/GlossaryService';


@Component({
  selector: 'navigation-bar',
  templateUrl: `./navigation.component.html`
})
export class NavigationComponent 
{    
	constructor(public glossaryService: GlossaryService, private documentService: DocumentService, fb: FormBuilder)
	{
        this.options = fb.group({
            bottom: 0,
            fixed: false,
            top: 0,
          });
    }

    options: FormGroup;

 
  shouldRun = true;
}