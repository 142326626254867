export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyCzSJwJvvOewaAhwkC-udEsfH38sUd-M1I",
    authDomain: "wealthly-d5bd5.firebaseapp.com",
    projectId: "wealthly-d5bd5",
    storageBucket: "wealthly-d5bd5.appspot.com",
    messagingSenderId: "925770596659",
    appId: "1:925770596659:web:65ec8c418ee33d8ea47e46",
    measurementId: "G-PSF9XJX6J1"
  }
};
